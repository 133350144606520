<template>
  <div>
    <eden-table-actions :title="title" @search="searchItems">
      <template slot="actions">
        <el-button v-if="allowActions" type="primary" @click="addNewItem">
          Add New Item
        </el-button>
      </template>
    </eden-table-actions>
    <template v-if="loading">Loading...</template>
    <template v-else>
      <el-table :data="pageData">
        <el-table-column min-width="200">
          <template slot="header">
            <span>Name</span>
          </template>
          <template v-slot="scope">
            <div class="is-flex is-align-center">
              <div class="eden-meal--image">
                <img
                  v-if="scope.row.image_url"
                  :src="getThumbnailFromCloudinary(scope.row.image_url)"
                  alt="Image"
                />
              </div>
              <router-link
                class="text-grey-primary"
                :to="{
                  name: 'services.service-type.edit',
                  params: { id: scope.row.id },
                }"
                >{{ scope.row.name }}
              </router-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="100">
          <template slot="header">
            <span>Category</span>
          </template>
          <template v-slot="scope">
            <div class="text-primary">
              {{ formatText(scope.row.class_category) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="100">
          <template slot="header">
            <span>Type</span>
          </template>
          <template v-slot="scope">
            <el-tag
              v-for="(category, i) in scope.row.meal_type.split(',')"
              :key="i"
              >{{ formatText(category) }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column width="100">
          <template slot="header">
            <span>Combos</span>
          </template>
          <template v-slot="scope">
            <span v-if="scope.row.preset_combos">{{
              scope.row.preset_combos && scope.row.preset_combos.length
            }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column width="200">
          <template slot="header">
            <span>Added on</span>
          </template>
          <template v-slot="scope">
            <span>{{
              formatDateAndTime(scope.row.created_at, "ddd do, m, y")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column width="200">
          <template slot="header">
            <span>Updated on</span>
          </template>
          <template v-slot="scope">
            <span>{{
              formatDateAndTime(scope.row.updated_at, "ddd do, m, y")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column width="140">
          <template slot="header">
            <span>Visible on Garden</span>
          </template>
          <template v-slot="scope">
            <meal-item-garden-status
              :status.sync="scope.row.visible_on_garden"
              :ids="[scope.row.id]"
            />
          </template>
        </el-table-column>
        <el-table-column v-if="allowActions" width="60">
          <template v-slot="scope">
            <service-type-actions
              :service-type="scope.row"
              :show-status-change="false"
              @updated="getItems"
            />
          </template>
        </el-table-column>
      </el-table>
      <eden-pagination
        v-if="showPagination"
        :from="pagination.from"
        :to="pagination.to"
        :total="pagination.total"
        :page-size="pagination.perPage"
        :current-page.sync="page"
      />
    </template>
  </div>
</template>

<script>
import meal from "@/requests/services/meal";
import ServiceTypeActions from "@/components/Services/Actions/ServiceTypeActions";

export default {
  name: "MealItems",
  components: {
    ServiceTypeActions,
    MealItemGardenStatus: () => import("./MealItemGardenStatus"),
  },
  data() {
    return {
      loading: false,
      page: 1,
      pageData: [],
      search: "",
      showPagination: true,
      pagination: {
        from: null,
        to: null,
        total: null,
        perPage: null,
      },
      sort: {
        property: "name",
        direction: "asc",
      },
    };
  },
  computed: {
    service() {
      return this.$route.params.service;
    },
    types() {
      return this.$store.getters.service;
    },
    from() {
      return this.types.pages[this.page]?.from;
    },
    to() {
      return this.types.pages[this.page]?.to;
    },
    total() {
      return this.types.total;
    },
    title() {
      const total = this.pagination.total;
      const result = this.pageData.length;
      return `${this.showPagination ? total : result} Types`;
    },
    allowActions() {
      return this.allowAccessFor(["admin", "operations", "kitchen"]);
    },
  },
  watch: {
    page() {
      if (!this.search) {
        this.getItems();
      } else {
        this.searchItems(this.search);
      }
    },
  },
  created() {
    this.getItems();
  },
  methods: {
    getItems() {
      this.loading = true;
      this.search = "";
      meal
        .list()
        .then(({ data }) => {
          this.pageData = data.data;
          this.pagination.from = data.meta.from;
          this.pagination.to = data.meta.to;
          this.pagination.total = data.meta.total;
          this.pagination.perPage = data.meta.per_page;
          this.showPagination = true;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    searchItems(query) {
      this.search = query;
      if (!query) {
        this.getItems();
        return;
      }
      this.loading = true;
      const fQuery = `${this.formatQueryText(query)}&page=${this.page}`;
      meal
        .search(fQuery)
        .then((response) => {
          this.pageData = response.data.data;
          this.pagination.from = response.data.meta.from;
          this.pagination.to = response.data.meta.to;
          this.pagination.total = response.data.meta.total;
          this.pagination.perPage = response.data.meta.per_page;
          this.showPagination = true;
          this.loading = false;
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          this.loading = false;
        });
    },

    addNewItem() {
      this.$router.push({ name: "services.service-type.add" });
    },
  },
};
</script>

<style lang="scss" scoped>
.eden-meal--image {
  height: 36px;
  width: 36px;
  min-height: 36px;
  min-width: 36px;
  border-radius: 100%;
  background: #798b83;
  margin-right: 20px;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 100%;
    background: #798b83;
  }

  + div {
    width: 70%;
  }
}
</style>
